import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { SidebarCategoryRoutes } from '../../../constants';
import { Callout, CalloutTitle, CalloutText } from '../../../components/callout';
export const pageQuery = graphql`
  {
    allPasteLibraries(filter: {name: {eq: "@twilio-paste/codemods"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    mdx(fields: {slug: {eq: "/libraries/codemods/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name="Codemods" categoryRoute={SidebarCategoryRoutes.LIBRARIES} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-codemods" data={props.data.allPasteLibraries.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`About`}</h2>
        <p><a parentName="p" {...{
            "href": "https://github.com/facebook/codemod#background"
          }}>{`Codemods`}</a>{` are a collection of scripts that
update your codebase from old patterns to new patterns automatically. In this way
we can effectively move APIs and patterns to newer versions without incurring
tech debt across the Twilio organization.`}</p>
        <p>{`A notable user of this approach is the `}<a parentName="p" {...{
            "href": "https://github.com/reactjs/react-codemod"
          }}>{`React team`}</a>{`.
They provide similar automation to downstream users when they introduce breaking changes
in React, which helps to alleviate the pain points and provide a path forward.`}</p>
        <p>{`We hope these codemods make your experience using and maintaining the latest version
of Paste easy and enjoyable.`}</p>
        <h2>{`Usage`}</h2>
        <pre><code parentName="pre" {...{}}>{`npx @twilio-paste/codemods [...options]
`}</code></pre>
        <p>{`Options:`}</p>
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`--dry`}</inlineCode>{` for a dry-run (doesn't change the files, just reports the status)`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`--print`}</inlineCode>{` to print the output for comparison`}</li>
        </ul>
        <p>{`This will start an interactive wizard, and then run the specified transform.`}</p>
        <h3>{`Included Transforms`}</h3>
        <h4>{`barreled-to-unbarreled`}</h4>
        <p>{`Converts old-style core imports to the new unbarreled style. (i.e.: `}<inlineCode parentName="p">{`import {Button} from '@twilio-paste/core'`}</inlineCode>{` becomes `}<inlineCode parentName="p">{`import {Button} from '@twilio-paste/core/button'`}</inlineCode>{`)`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npx @twilio-paste/codemods barreled-to-unbarreled <path>
`}</code></pre>
        <h3>{`jscodeshift options`}</h3>
        <p>{`To pass more options directly to jscodeshift, use `}<inlineCode parentName="p">{`--jscodeshift="..."`}</inlineCode>{`. For example:`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npx @twilio-paste/codemods --jscodeshift="--run-in-band --verbose=2"
`}</code></pre>
        <p>{`See `}<a parentName="p" {...{
            "href": "https://github.com/facebook/jscodeshift#usage-cli"
          }}>{`all available jscodeshift options`}</a>{`.`}</p>
        <h3>{`Recast Options`}</h3>
        <p>{`Options to `}<a parentName="p" {...{
            "href": "https://github.com/benjamn/recast"
          }}>{`recast`}</a>{`'s printer can be provided
through jscodeshift's `}<inlineCode parentName="p">{`printOptions`}</inlineCode>{` command line argument`}</p>
        <pre><code parentName="pre" {...{
            "className": "language-sh"
          }}>{`npx @twilio-paste/codemods <transform> <path> --jscodeshift="--printOptions='{\\"quote\\":\\"double\\"}'"
`}</code></pre>
        <h2>{`Acknowledgements`}</h2>
        <p>{`This project is heavily inspired from `}<a parentName="p" {...{
            "href": "https://github.com/reactjs/react-codemod"
          }}>{`react-codemods`}</a>{`.`}</p>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      